<template>
  <div>
    <TB :tb_config="tb">
      <e-columns>
        <e-column
          field="id"
          headerText="ID"
          type="number"
          :isPrimaryKey="true"
          clipMode="EllipsisWithTooltip"
          :is-identity="true"
          :allowEditing="false"
          :visible=false
        ></e-column>
        <e-column
          :field="
            'ppl.productproducer.category.parent.translations.' +
            this.$i18n.locale +
            '.name'
          "
          headerText="Macrocategoria"
          :allowEditing="false"
          editType="textedit"
          clipMode="EllipsisWithTooltip"
          :template="mcTemplate"
          :visible=false
        >
        </e-column>
        <e-column
          :field="
            'ppl.productproducer.category.translations.' +
            this.$i18n.locale +
            '.name'
          "
          headerText="Categoria"
          :allowEditing="false"
          editType="textedit"
          clipMode="EllipsisWithTooltip"
          :template="ccTemplate"
        >
        </e-column>
        <e-column
          field="ppl.productproducer.producer.role.name"
          headerText="Produttore"
          :allowEditing="false"
          editType="textedit"
          clipMode="EllipsisWithTooltip"
          :template="rpTemplate"
        ></e-column>
        <e-column
          :field="
            'ppl.productproducer.translations.' + this.$i18n.locale + '.name'
          "
          headerText="Prodotto"
          :allowEditing="false"
          editType="textedit"
          clipMode="EllipsisWithTooltip"
          :template="ppTemplate"
        ></e-column>
        <e-column
          v-bind:field="'ppl.translations.' + this.$i18n.locale + '.name'"
          headerText="Variante"
          type="string"
          :allowEditing="false"
          clipMode="EllipsisWithTooltip"
          :template="pplTemplate"
        ></e-column>
        <e-column
          field="ppm.force_manual_price"
          :headerText="$t('models.ppm.force_manual_price')"
          displayAsCheckBox="true"
          type="boolean"
          editType="booleanedit"
        ></e-column>
        <e-column
          field="ppm.active"
          :headerText="$t('models.lot.is_active')"
          displayAsCheckBox="true"
          type="boolean"
          editType="booleanedit"
        ></e-column>
        <e-column
          field="ppm.price"
          type="number"
          :headerText="$t('models.ppm.price')"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          headerText="Margine"
          :template="mrgTemplate"
          :allowEditing="false"
        >
        </e-column>
        <e-column
          field="ppm.flag"
          :headerText="$t('models.ppm.flag')"
          foreignKeyValue="label"
          :dataSource="Flags"
          foreignKeyField="value"
          clipMode="EllipsisWithTooltip"
        ></e-column>

        <e-column
          field="ppl.price"
          type="number"
          :headerText="$t('models.lot.price')"
          clipMode="EllipsisWithTooltip"
          :allowEditing="false"
        ></e-column>
        <e-column
          field="ppl.price_len"
          type="number"
          :headerText="$t('models.lot.price_len')"
          clipMode="EllipsisWithTooltip"
          :allowEditing="false"
          :visible=false
        ></e-column>
        <e-column
          field="ppl.price_unit"
          :headerText="$t('models.lot.price_unit')"
          foreignKeyValue="label"
          :dataSource="Units"
          foreignKeyField="value"
          clipMode="EllipsisWithTooltip"
          :allowEditing="false"
          :visible=false
        ></e-column>
        <e-column
          field="ppl.price_len_mol"
          :headerText="$t('models.lot.price_len_mol')"
          displayAsCheckBox="true"
          type="boolean"
          editType="booleanedit"
          :allowEditing="false"
          :visible=false
        ></e-column>

        <e-column
          field="ppl.price_sugg"
          type="number"
          :headerText="$t('models.lot.price_sugg')"
          clipMode="EllipsisWithTooltip"
          :allowEditing="false"
          :visible=false
        ></e-column>
        <e-column
          field="ppl.pw"
          :headerText="$t('models.lot.pw')"
          displayAsCheckBox="true"
          type="boolean"
          editType="booleanedit"
          :allowEditing="false"
          :visible=false
        ></e-column>
        <e-column
          field="ppl.pw_len"
          type="number"
          :headerText="$t('models.lot.pw_len')"
          clipMode="EllipsisWithTooltip"
          :allowEditing="false"
          :visible=false
        ></e-column>
        <e-column
          field="ppl.pw_unit"
          :headerText="$t('models.lot.pw_unit')"
          foreignKeyValue="label"
          :dataSource="Units"
          foreignKeyField="value"
          clipMode="EllipsisWithTooltip"
          :allowEditing="false"
          :visible=false
        ></e-column>
        <!-- <e-column headerText="Pezzi venduti"> </e-column>
        <e-column headerText="Ultimo ordine"> </e-column> -->
        <e-column
          field="ppl.ts_created"
          :headerText="$t('common.table.created')"
          type="dateTime"
          :format="tsFormat"
          :allowEditing="false"
          clipMode="EllipsisWithTooltip"
          :visible=false
        ></e-column>
        <e-column
          field="ppl.ts_modified"
          :headerText="$t('common.table.last_modified')"
          type="dateTime"
          :format="tsFormat"
          :allowEditing="false"
          clipMode="EllipsisWithTooltip"
          :visible=false
        ></e-column>
      </e-columns>
    </TB>
  </div>
</template>

<script>
import Vue from "vue";
import TB from "../../../common/TB";
import { GetDataManager } from "../../../ds/index";
import { DataManager } from "@syncfusion/ej2-data";

export default Vue.extend({
  name: "Products",
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: GetDataManager("role_market_ppm", [this.$store.state.role.id]),
        searchOptions: {
          fields: [
            "ppl.productproducer.category.parent.translations." +
              this.$i18n.locale +
              ".name",
            "ppl.productproducer.category.translations." +
              this.$i18n.locale +
              ".name",
            "ppl.productproducer.producer.role.name",
            "ppl.productproducer.translations." + this.$i18n.locale + ".name",
            "ppl.translations." + this.$i18n.locale + ".name",
            "ppl.id",
          ],
        },
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Normal",
        },
        toolbar: ["Edit", "Update", "Cancel"],
      },
      // ProductData: GetDataManager("role_producer_products", [
      //   this.$store.state.role.id,
      // ]),
      Units: new DataManager([
        { value: 1, label: this.$t("models.lot.units.option1") },
        { value: 2, label: this.$t("models.lot.units.option2") },
        { value: 3, label: this.$t("models.lot.units.option3") },
        { value: 4, label: this.$t("models.lot.units.option4") },
        { value: 5, label: this.$t("models.lot.units.option5") },
        { value: 6, label: this.$t("models.lot.units.option6") },
      ]),
      Flags: new DataManager([
        { value: 1, label: this.$t("models.ppm.flags.options1") },
        { value: 2, label: this.$t("models.ppm.flags.option2") },
        { value: 3, label: this.$t("models.ppm.flags.option3") },
        { value: 4, label: this.$t("models.ppm.flags.option4") },
        { value: 5, label: this.$t("models.ppm.flags.option5") },
        { value: 6, label: this.$t("models.ppm.flags.option6") },
      ]),

      tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
      mcTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="image">
                    <img v-if="image" :src="image" :alt="name"/> {{name}}
                </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              image: function () {
                return this.data.ppl.productproducer.category.parent
                  .image_thumb;
              },
              name: function () {
                return this.data.ppl.productproducer.category.parent
                  .translations[this.$i18n.locale].name;
              },
            },
          }),
        };
      },
      ccTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="image">
                    <img v-if="image" :src="image" :alt="name"/> {{name}}
                </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              image: function () {
                return this.data.ppl.productproducer.category.image_thumb;
              },
              name: function () {
                return this.data.ppl.productproducer.category.translations[
                  this.$i18n.locale
                ].name;
              },
            },
          }),
        };
      },
      rpTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="image">
                    <img v-if="image" :src="image" :alt="name"/> {{name}}
                </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              image: function () {
                return this.data.ppl.productproducer.producer.role.image_thumb;
              },
              name: function () {
                return this.data.ppl.productproducer.producer.role.name;
              },
            },
          }),
        };
      },
      ppTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="image">
                    <img v-if="image" :src="image" :alt="name"/> {{name}}
                </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              image: function () {
                return this.data.ppl.productproducer.image_thumb;
              },
              name: function () {
                return this.data.ppl.productproducer.translations[
                  this.$i18n.locale
                ].name;
              },
            },
          }),
        };
      },
      pplTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="image">
                    <img v-if="image" :src="image" :alt="name"/> {{name}}
                </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              image: function () {
                return this.data.ppl.image_thumb;
              },
              name: function () {
                return this.data.ppl.translations[this.$i18n.locale].name;
              },
            },
          }),
        };
      },
      mrgTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                    {{mrg.toFixed(2)}}<br>
                    <small>{{mrg_p.toFixed(0)}}%{{mrg_auto}}</small>
                </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              mrg: function () {
                return this.data.ppm.price - this.data.ppl.price;
              },
              mrg_p: function () {
                // return ((this.data.ppm.price * 100) / this.data.ppl.price)-100;
                return (this.mrg*100)/this.data.ppm.price;
              },
              mrg_auto: function () {
                if(this.data.auto_price){
                  return " Auto"
                }
                return ""
              },
            },
          }),
        };
      },
    };
  },
});
</script>
